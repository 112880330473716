import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./app/Dashboard";

// NOTIFICATION
import Loader from "./app/common/Loader";
import Appstrore from "./app/mpagesDashboard/Appstrore";
import NotFound from "./app/NotFound";

const Home = lazy(() => import("./app/Notification/Pages/Home"));
const HomeWork = lazy(() => import("./app/Notification/components/HomeW"));

const NativeDashboard = lazy(() =>
  import("./app/mpagesDashboard/NativeDashboard")
);

const ClassWork = lazy(() => import("./app/Notification/components/ClassWork"));
const Notes = lazy(() => import("./app/Notification/components/Notes"));
const TeacherView = lazy(() =>
  import("./app/StudentPortfolio/TeacherView/TeacherView")
);

const TeacherNotes = lazy(() =>
  import("./app/Notification/NotesUpload/NotesUpload")
);

const LeaveReport = lazy(() => import("./app/TeacherLeaveReport/LeaveReport"));

//?  Student Portfolio App

const AdminPortfolio = lazy(() =>
  import("./app/StudentPortfolio/Admin/Report")
);

const CreatePortfolio = lazy(() =>
  import("./app/StudentPortfolio/TeacherView/CreatePortfolio")
);

const ViewPortfolio = lazy(() =>
  import("./app/StudentPortfolio/StudentView/StudentView")
);

const PortFolioHome = lazy(() =>
  import("./app/StudentPortfolio/ReusableComponants/Dashboard")
);

//? Exam Module App

const ExamReport = lazy(() => import("./app/ExamModule/Listview"));

//? Assignment Module

const AssignmentHome = lazy(() => import("./app/Assignment/Home"));
const ListAssigment = lazy(() => import("./app/Assignment/Listview"));

// QR Code Module

const QRCodeHome = lazy(() => import("./app/QRcode/Home"));

// Teacher Dashboard

const TeacherDashboard = lazy(() => import("./TDashboard/Home"));

// Parent Dashboard

const ParentDashboard = lazy(() => import("./TDashboard/ParentHome"));

const ScannAttend = lazy(() => import("./app/AttendanceMarker/index"));

const ScannHome = lazy(() => import("./app/AttendanceMarker/Home"));

const AttendanceReport = lazy(() => import("./app/AttendanceMarker/Report"));
// Transport Management System
const TransportHome = lazy(() => import("./app/TransportManagement/Home"));
const Pickup = lazy(() => import("./app/TransportManagement/Pickup/Pickup"));
const PickupStudent = lazy(() =>
  import("./app/TransportManagement/Pickup/Scan")
);

const TransportAttendanceReport = lazy(() =>
  import("./app/TransportManagement/Admin/Report/Report.jsx")
);

const DropStudent = lazy(() => import("./app/TransportManagement/Drop/Scan"));

const Drop = lazy(() => import("./app/TransportManagement/Drop/Drop"));

// Custom App links

const CustomApp = lazy(() => import("./app/mpagesDashboard/CustomApp"));
const CustomUI = lazy(() => import("./app/mpagesDashboard/CustomUI"));

// SCHOOL MANAGEMENT -:

const SchoolHome = lazy(() => import("./app/SchoolManagement/Home.jsx"));

const SchoolDetails = lazy(() => import("./app/SchoolManagement/Report.jsx"));

const TrainingForm = lazy(() => import("./app/TrainingForm/TrainingForm"));

const StaffAttend = lazy(() => import("./app/StaffAttendance/StaffAttend.jsx"));

const MedicalForm = lazy(() => import("./app/MedicalRecord/MedicalForm.jsx"));

const MedicalFormSettings = lazy(() => import("./app/MedicalRecord/MedicalFormSettings.jsx"));

const MedicalFormReport = lazy(() => import("./app/MedicalRecord/MedicalFormReport.jsx"));

const EduFundRedirect = lazy(() => import("./app/EduFundRedirect/EduFundRedirect.jsx"));

// INCIDENCE FORM -:

const IncidenceHome = lazy(() =>
  import("./app/IncidenceForm/Teacher/Home.jsx")
);
const FormHome = lazy(() => import("./app/IncidenceForm/Teacher/Form.jsx"));
const IncReport = lazy(() => import("./app/IncidenceForm/Teacher/Report.jsx"));
const UpdateIncReport = lazy(() =>
  import("./app/IncidenceForm/Teacher/UpdateReport.jsx")
);

const StudentIncReport = lazy(() =>
  import("./app/IncidenceForm/Student/Report.jsx")
);

// STUDENT HOME :
const StudentProfileHome = lazy(() => import("./app/Profile/Home.jsx"));

// STUDENT  PROFILE :
const StudentProfile = lazy(() => import("./app/Profile/Home.jsx"));

// STUDENT LEAVE:

const StudentLeave = lazy(() => import("./app/StudentLeave/Home.jsx"));

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path="/school/profile/:sid/:stud_id"
              element={<StudentProfileHome />}
            />

            <Route
              path="/student/leave/:sid/:stud_id"
              element={<StudentLeave />}
            />

            {/* School Managemnt */}

            <Route path="/school/home/:sid" element={<SchoolHome />} />

            <Route
              path="/student/profile/:sid/:userid"
              element={<StudentProfile />}
            />

            <Route
              path="/school/details/:sid/:staff_id"
              element={<SchoolDetails />}
            />

            {/* custom App */}
            <Route path="/custom/app/:sid" element={<CustomApp />} />

            <Route path="/custom/app/ui/:id/:db" element={<CustomUI />} />
            {/* Notification APP */}

            <Route path="/training" element={<TrainingForm />} />

            <Route path="/staffAttend/:sid/:staff_id" element={<StaffAttend />} />
            
            <Route path="/MedicalForm/:sid" element={<MedicalForm />} />
            
            <Route path="/edufund/:uid/:gid/:schoolid" element={<EduFundRedirect />} />

            <Route path="/MedicalFormSettings/:sid" element={<MedicalFormSettings />} />

            <Route path="/MedicalFormReport/:sid" element={<MedicalFormReport />} />

            <Route
              path="/transport/home/:sid/:staff_id"
              element={<TransportHome />}
            />
            <Route
              path="/transport/pickup/:sid/:staff_id"
              element={<Pickup />}
            />

            <Route path="/transport/drop/:sid/:staff_id" element={<Drop />} />
            <Route
              path="/transport/pickup/student/:sid/:staff_id"
              element={<PickupStudent />}
            />
            <Route
              path="/transport/drop/student/:sid/:staff_id"
              element={<DropStudent />}
            />

            <Route path="/transport/drop/:sid/:staff_id" element={<Drop />} />

            <Route
              path="/transport/report/:sid/:staff_id"
              element={<TransportAttendanceReport />}
            />

            <Route path="/QR/home/:sid/:staff_id" element={<ScannHome />} />
            <Route path="/scanQR/:sid/:staff_id" element={<ScannAttend />} />
            <Route
              path="/scanQR/attendance/report/:sid/:staff_id"
              element={<AttendanceReport />}
            />

            <Route path="/notification/home/:sid/:uid" element={<Home />} />
            <Route
              path="/notification/Home-Work/:sid/:uid"
              element={<HomeWork />}
            />
            <Route
              path="/notification/Class-Work/:sid/:uid"
              element={<ClassWork />}
            />
            <Route path="/notification/Notes/:sid/:uid" element={<Notes />} />

            {/* Teacher Upload Notes for student */}

            <Route
              path="/Notes/upload/:sid/:staff_id"
              element={<TeacherNotes />}
            />

            {/* Leave Report */}
            <Route
              path="/teacherviewofstudentleave/:sid/:uid"
              element={<LeaveReport />}
            />
            {/* Student PortFolio */}

            <Route
              path="/student-portfolio/home/:sid/:staff_id"
              element={<PortFolioHome />}
            />

            <Route
              path="/student-portfolio/:sid/:staff_id"
              element={<CreatePortfolio />}
            />
            <Route
              path="/portfolio/report/teacher/:sid/:staff_id"
              element={<TeacherView />}
            />

            <Route
              path="/portfolio/admin/report/:sid/:adminId"
              element={<AdminPortfolio />}
            />

            <Route
              path="/portfolio/report/student/:sid/:stud_id"
              element={<ViewPortfolio />}
            />

            <Route
              path="/teacheracademicdashboard/:sid/:staffid"
              element={<NativeDashboard />}
            />

            <Route path="/" element={<Dashboard />} />

            {/* Exam Module */}

            <Route path="/examReport/:sid/:uid" element={<ExamReport />} />

            {/* QR code */}

            <Route path="/QRhome/:sid/:uid" element={<QRCodeHome />} />
            {/* Assignment Module */}

            <Route
              path="/Assignment/home/:sid/:uid"
              element={<AssignmentHome />}
            />

            <Route
              path="/assignment/:sid/:uid/:id"
              element={<ListAssigment />}
            />

            {/* Teacher Dashboard */}

            <Route
              path="/Teacher/home/:sid/:uid"
              element={<TeacherDashboard />}
            />

            <Route
              path="/Parent/Dashboard/:sid/:uid"
              element={<ParentDashboard />}
            />

            <Route path="/parent/app/:sid" element={<Appstrore />} />
            <Route path="/teacher/app/:sid" element={<Appstrore />} />
            <Route path="/admin/app/:sid" element={<Appstrore />} />
            <Route path="*" element={<NotFound />} />

            {/* INCIDENCE FORM TEACHER*/}
            <Route
              path="/incidence/home/:sid/:staff_id"
              element={<IncidenceHome />}
            />
            <Route
              path="/incidence/form/:sid/:staff_id"
              element={<FormHome />}
            />
            <Route
              path="/incidence/report/:sid/:staff_id"
              element={<IncReport />}
            />
            <Route
              path="/incidence/student/report/:sid/:stud_id"
              element={<StudentIncReport />}
            />
            <Route
              path="/incidence/update/report/:sid/:staff_id"
              element={<UpdateIncReport />}
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
